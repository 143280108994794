<template>
  <v-container fluid class="ma-4 pr-10">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Configuração de lote de saída</h1>
      </v-col>
    </v-row>
    <v-tabs
      color="primary"
      background-color="var(--v-background-base)"
      v-model="tab"
      class="mb-4"
    >
      <v-tab key='configs' href='#configs' style="font-size: 18px">Configurações</v-tab>
      <v-tab key='restrict-contract' href='#restrict-contract' style="font-size: 18px">Contratos Restritos</v-tab>
    </v-tabs>

    <v-form ref="validation" lazy-validation>
      <v-tabs-items
        v-model="tab"
        grow
        style="background-color: var(--v-background-base)"
      >
        <v-tab-item key='configs' value='configs'>
          <v-row>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Tipo de lote
              <v-autocomplete
                class="font-weight-regular"
                :items="batchTypeList"
                item-text="name"
                item-value="value"
                v-model="batch.batchType"
                @change="handleBatchTypeChange()"
                outlined
                placeholder="Informe o tipo de lote"
                color="textPrimary"
                :rules="[rule.requiredArray]"
                validate-on-blur
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Layout
              <v-autocomplete
                class="font-weight-regular"
                :items="layoutList"
                :loading="isLoadingLayoutList"
                item-text="name"
                item-value="id"
                v-model="batch.layoutId"
                outlined
                placeholder="Selecione o layout"
                color="textPrimary"
                validate-on-blur
                :disabled="batch.batchType === 'API'"
                :rules="batch.batchType === 'FILE' ? [rule.required] : []"
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Granularidade
              <v-autocomplete
                class="font-weight-regular"
                :items="generateByList"
                v-model="batch.generateBy"
                outlined
                placeholder="Selecione a granularidade"
                color="textPrimary"
                :rules="[rule.required]"
                validate-on-blur
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" xl="3" lg="3" md="3">
              Periodicidade
              <v-text-field
                v-model="batch.cronExpression"
                class="font-weight-regular"
                placeholder="Informe a periodicidade"
                outlined
                color="textPrimary"
                :rules="[rule.required]"
                :error-messages="cronExpressionError ? `*${cronExpressionError.message}` : ''"
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Permite Agendamento
              <v-autocomplete
                class="font-weight-regular"
                :items="this.batch.batchType !=='FILE' ? [] : allowSchedulingOptions"
                item-text="name"
                item-value="value"
                v-model="batch.allowScheduling"
                outlined
                :disabled="this.batch.batchType !=='FILE'"
                placeholder="Informe se permite agendamento"
                color="textPrimary"
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Benefício
              <v-autocomplete
                class="font-weight-regular"
                :items="benefitTypes && benefitTypes.length > 0 ? benefitTypes : []"
                :loading="isLoadingBenefitTypeList"
                item-text="name"
                item-value="xipp_contract_record_type_code"
                v-model="batch.benefitType"
                outlined
                placeholder="Informe o benefício"
                color="textPrimary"
                :rules="[rule.requiredArray]"
                validate-on-blur
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Número mínimo de vidas
              <v-text-field
                v-model="batch.minLife"
                @keydown="$event.key === '-' ? $event.preventDefault() : null"
                @blur="batch.minLife ? rule.verifyNegative(batch.minLife, 'fieldCheckedBatchMinLife') : ''"
                @focus="verifyMessageBatchMinLife = rule.isFocusVerifyNegative(true, batch.minLife)"
                @change="verifyMessageBatchMinLife = rule.isFocusVerifyNegative(false, batch.minLife)"
                id="fieldCheckedBatchMinLife"
                v-mask="'#########'"
                :error-messages="verifyMessageBatchMinLife ? '*Preencha corretamente' : ''"
                class="font-weight-regular"
                outlined
                placeholder="Informe o número de vidas"
                color="textPrimary"
                :rules="[rule.required, rule.validateNegativeDecimal(batch.minLife, 'int')]"
                validate-on-blur
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Responsável
              <v-autocomplete
                class="font-weight-regular"
                :items="users && users.length > 0 ? users : []"
                item-text="name"
                item-value="id"
                v-model="batch.userId"
                outlined
                placeholder="Informe o responsável"
                color="textPrimary"
                validate-on-blur
                :disabled="true"
                readonly
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Status
              <v-autocomplete
                class="font-weight-regular"
                :items="batchStatusList"
                item-text="name"
                item-value="value"
                v-model="batch.status"
                outlined
                placeholder="Informe o status"
                color="textPrimary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="3" lg="3" xl="3">
              Tipo de Movimentação
              <v-autocomplete
                class="font-weight-regular"
                :items="movementTypeList"
                :loading="isLoadingMovementTypeList"
                v-model="batch.movementTypeId"
                item-text="name"
                item-value="id"
                :rules="[rule.requiredArray]"
                outlined
                color="textPrimary"
                multiple
                small-chips
                deletable-chips
                hide-selected
                persistent-hint
                validate-on-blur
                @focus="watchFocus"
                clearable
                placeholder="Informe a movimentação"
                :label="(batch.movementTypeId && batch.movementTypeId.length > 0) || watchFocus() ? '' : 'Informe a movimentação'"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key='restrict-contract' value='restrict-contract'>
          <v-row>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="4" lg="3" xl="3">
              <label class="label">Grupo Financeiro</label>
              <v-autocomplete
                v-model="search.financialGroup"
                :items="financialGroups"
                :loading="isLoadingFinancialGroups"
                item-text="name"
                item-value="id"
                placeholder="Grupo Financeiro"
                outlined
                clearable
                color="textPrimary"
                @input="loadContractByFinancialGroup(search)"
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="4" lg="3" xl="3">
              <label class="label">Classificação da Empresa</label>
              <v-autocomplete
                v-model="search.companyClassification"
                :items="companyClassificationArr"
                placeholder="Todos"
                outlined
                clearable
                color="textPrimary"
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="4" lg="3" xl="3">
              <label class="label">Tipo de Contrato</label>
              <v-autocomplete
                v-model="search.contractType"
                :items="contractsTypeArr"
                placeholder="Todos"
                outlined
                clearable
                color="textPrimary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-subtitle-1 primary--text font-weight-medium my-md-n10 my-lg-n10 my-xl-n10" cols="12" md="4" lg="6" xl="6">
              <label class="label">Contrato/Apólice</label>
              <v-autocomplete
                v-model="search.contract"
                :items="contractByFinancialGroupList"
                item-text="name"
                item-value="id"
                placeholder="Todos"
                outlined
                clearable
                color="textPrimary"
              />
            </v-col>
            <v-col class="text-subtitle-1 primary--text font-weight-medium my-md-n10 my-lg-n10 my-xl-n10" cols="12" md="4" lg="3" xl="3">
              <label class="label">Filtrar por</label>
              <v-autocomplete
                v-model="search.selectedItems"
                :items="selectedItemsArr"
                placeholder="Todos"
                outlined
                clearable
                color="textPrimary"
              />
            </v-col>
            <v-col class="my-md-n3 my-lg-n3 my-xl-n3" cols="12" md="4" lg="3" xl="3">
              <v-btn class="mr-5" color="primary" @click="loadAllContractsByFilters()" width="100%" min-height="55">
                <v-icon style="font-size: 18px;" class="mr-2">fa-search</v-icon>Pesquisar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-2" cols="12">
              <v-data-table
                v-model="restrictContracts"
                class="elevation-1"
                :headers="headers"
                :items="filteredContracts"
                :loading="isLoading"
                :items-per-page="10"
                item-key="id"
                show-select
              >
                <template v-slot:no-data>
                  <span>Nenhum dado encontrado.</span>
                </template>
                <template v-slot:[`item.contract`]="{ item }">
                  {{ item.name ? item.name : "-" }}
                </template>
                <template v-slot:[`item.contractType`]="{ item }">
                  {{ item.contract_type_id ? item.contract_type_id : "-" }}
                </template>
                <template v-slot:[`item.financialGroup`]="{ item }">
                  {{ item.financial_group_id ? item.financial_group_id.name : "-" }}
                </template>
                <template v-slot:[`item.companyClassification`]="{ item }">
                  {{ item.company_classification ? item.company_classification : "-" }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-form>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>
    <v-footer>
      <v-row class="d-flex justify-end mt-10">
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickBack()"
        >
          Voltar
        </v-btn>
        <v-btn
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="createdIdAndValidation()"
        >
          Salvar
        </v-btn>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import MovementRecordTypeService from '@/services-http/sdi/MovementRecordTypeService';
import Rules from '@/shared/validators/formRules';
import LayoutService from '@/services-http/sdi/LayoutService';
import BenefitService from '@/services-http/odoo/BenefitService';
import InsuranceCarrierBatchConfigService from '@/services-http/sdi/InsuranceCarrierBatchConfigService';
import ContractService from '@/services-http/contract/ContractService';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import { mapGetters } from 'vuex';

export default {
  name: 'BatchConfigNew',
  data() {
    return {
      tab: 'configs',
      overlay: false,
      isLoadingLayoutList: false,
      isLoadingBenefitTypeList: false,
      isLoadingMovementTypeList: false,
      insertAndUpdateBatchesDialog: false,
      validationForm: false,
      cronExpressionError: null,
      type: 'new',
      batch: {
        id: 0,
        insuranceCarrierId: 0,
        benefitType: '',
        movementTypeId: [],
        movementType: [],
        minLife: '',
        layoutId: '',
        generateBy: '',
        batchType: '',
        cronExpression: '',
        userId: '',
        status: 0,
        allowScheduling: null,
      },
      snackbar: {
        type: '',
        show: false,
        text: '',
      },
      movementTypeList: [],
      layoutList: [],
      benefitTypes: [],
      users: [],
      batchTypeList: [
        { name: 'Arquivo', value: 'FILE' },
        { name: 'API', value: 'API' },
      ],
      batchStatusList: [
        { name: 'Ativo', value: 1 },
        { name: 'Inativo', value: 0 },
      ],
      allowSchedulingOptions: [
        { name: 'Sim', value: true },
        { name: 'Não', value: false },
      ],
      generateByList: [
        { text: 'Grupo Financeiro', value: 'FINANCIAL_GROUP' },
        { text: 'Contrato', value: 'CONTRACT' },
        { text: 'Sub-Contrato', value: 'SUBCONTRACT' },
        { text: 'Operadora', value: 'INSURANCE_CARRIER' },
      ],
      verifyMessageBatchMinLife: false,
      hintLabel: true,
      headers: [
        {
          text: 'Contrato/Apólice',
          value: 'contract',
          align: 'left',
          width: '34%',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Tipo de Contrato',
          value: 'contractType',
          align: 'left',
          width: '22%',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Grupo Financeiro',
          value: 'financialGroup',
          align: 'left',
          width: '22%',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Classificação da Empresa',
          value: 'companyClassification',
          align: 'left',
          width: '22%',
          sortable: false,
          class: 'text--truncate',
        },
      ],
      search: {
        financialGroup: null,
        companyClassification: null,
        contractType: null,
        contract: null,
        selectedItems: null,
      },
      isLoadingFinancialGroups: false,
      isLoading: false,
      allContracts: [],
      filteredContracts: [],
      page: 1,
      totalPages: 0,
      financialGroups: [],
      contractByFinancialGroupList: [],
      contractsTypeArr: [],
      companyClassificationArr: [],
      selectedItemsArr: ['Selecionados', 'Não Selecionados'],
      restrictContracts: [],
    };
  },

  mounted() {
    if (this.$route.query.insuranceCarrierId) {
      this.batch.insuranceCarrierId = parseInt(this.$route.query.insuranceCarrierId, 10);
      this.loadInsuranceCarrierLayoutList(this.batch.insuranceCarrierId);
    }

    this.loadFinancialGroups();
    this.loadAllContracts();
    this.loadBenetiType();
    this.loadMovementType();

    if (this.$route.params.id) {
      this.type = 'edit';
      this.batch.id = this.$route.params.id;
      this.insuranceCarrierBatchConfigService.FindById(this.$route.params.id).then((response) => {
        this.batch.insuranceCarrierId = response.data.insuranceCarrierId;
        this.loadInsuranceCarrierLayoutList(this.batch.insuranceCarrierId);
        this.batch.benefitType = response.data.benefitType;
        this.batch.movementTypeId = response.data.movementTypes.map((mv) => mv.id);
        this.batch.minLife = response.data.minLife;
        if (response.data.layout) {
          this.batch.layoutId = response.data.layout.id;
        }
        this.batch.generateBy = response.data.generateBy;
        this.batch.batchType = response.data.batchType;
        this.batch.cronExpression = response.data.cronExpression;
        this.batch.userId = response.data.userId;
        this.batch.status = response.data.status ? 1 : 0;

        if(this.batch.batchType === 'FILE'){
          this.batch.allowScheduling = response.data.allowScheduling ? response.data.allowScheduling : false;
        }

        this.restrictContracts = response.data.restrictContracts ? response.data.restrictContracts.map((contract) => ({ id: contract.contractId })) : [];
      });
    }
  },

  methods: {
    updateAllowScheduling(batch) {
      if(batch.batchType === 'API') batch.allowScheduling = null
    },
    loadFinancialGroups() {
      this.isLoadingFinancialGroups = true;
      this.financialGroupService.FindAll().then((response) => {
        if (response && response.data) {
          this.financialGroups = response.data;
        }
        this.isLoadingFinancialGroups = false;
      }).catch(() => {
        this.isLoadingFinancialGroups = false;
      });
    },

    loadContractByFinancialGroup(search) {
      this.contractByFinancialGroupList = this.allContracts.filter((c) => c.financial_group_id.id === search.financialGroup);
    },
    loadAllContracts() {
      this.isLoading = true;
      this.contractService.FindAllByFilters(`onlyMainFields=true&carrierId=${this.$route.query.insuranceCarrierId}`).then((response) => {
        if (response && response.data) {
          this.isLoading = false;
          this.totalPages = response.data.totalPages;
          this.allContracts = response.data;
          this.filteredContracts = this.allContracts;
          this.contractByFinancialGroupList = this.allContracts;
          this.loadCombos(this.allContracts);
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    loadCombos(elements) {
      const contractsTypeSet = new Set();
      const companyClassificationSet = new Set();
      elements.forEach((element) => {
        contractsTypeSet.add(element.contract_type_id);
        companyClassificationSet.add(element.company_classification);
      });
      this.contractsTypeArr = Array.from(contractsTypeSet);
      this.companyClassificationArr = Array.from(companyClassificationSet);
    },
    loadAllContractsByFilters() {
      const retrictContractIds = this.restrictContracts.map((rc) => parseInt(rc.id));
      this.filteredContracts = this.allContracts;
      if (this.search.financialGroup) {
        this.filteredContracts = this.filteredContracts.filter((contract) => contract.financial_group_id.id === this.search.financialGroup);
      }

      if (this.search.companyClassification) {
        this.filteredContracts = this.filteredContracts.filter((contract) => contract.company_classification === this.search.companyClassification);
      }

      if (this.search.contractType) {
        this.filteredContracts = this.filteredContracts.filter((contract) => contract.contract_type_id === this.search.contractType);
      }

      if (this.search.contract) {
        this.filteredContracts = this.filteredContracts.filter((contract) => contract.id === this.search.contract);
      }

      if (this.search.selectedItems === 'Selecionados') {
        this.filteredContracts = this.filteredContracts.filter((contract) => retrictContractIds.includes(parseInt(contract.id)));
      } else if (this.search.selectedItems === 'Não Selecionados') {
        this.filteredContracts = this.filteredContracts.filter((contract) => !retrictContractIds.includes(parseInt(contract.id)));
      }
    },
    onClickBack() {
      this.$router.push({ name: 'BatchConfiguration' });
    },
    createdIdAndValidation() {
      if (this.$refs.validation.validate()) {
        this.setIdLayoutAndMovementType();
        if (this.type === 'edit') {
          this.saveEditBatch();
        } else if (this.type === 'new') {
          this.saveNewBatch();
        }
      } else {
        this.tab = 'configs';
        this.customizeSnackbarMessage('error', 'Verifique os campos');
      }
    },
    setIdLayoutAndMovementType() {
      this.batch.movementType = [];
      this.movementTypeList.forEach((element) => {
        this.batch.movementTypeId.forEach((item) => {
          if (element.id === item) {
            this.batch.movementType.push(element);
          }
        });
      });
      if (this.batch.layoutId) {
        this.batch.layout = { id: this.batch.layoutId };
      }
    },
    saveEditBatch() {
      const batchAux = {
        id: this.batch.id,
        benefitType: this.batch.benefitType,
        generateBy: this.batch.generateBy,
        insuranceCarrierId: JSON.parse(JSON.stringify(this.batchInsuranceCarrierId)),
        layout: this.batch.layout,
        minLife: this.batch.minLife,
        movementTypes: this.batch.movementType,
        batchType: this.batch.batchType,
        status: this.batch.status === 1,
        userId: this.batch.userId,
        cronExpression: this.batch.cronExpression,
        allowScheduling: this.batch.allowScheduling,
        restrictContracts: this.restrictContracts.map((contract) => ({ contractId: contract.id })),
      };
      this.insuranceCarrierBatchConfigService.UploadBatch(batchAux.id, batchAux).then((response) => {
        this.$emit('reloadTable', batchAux.insuranceCarrierId);
        this.customizeSnackbarMessage('success', 'Configuração editada com sucesso');
        this.$router.push({ name: 'BatchConfiguration' });
      }).catch((error) => {
        this.cronExpressionError = error.response.data.fields.find((f) => f.field === 'cronExpression');
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao criar configuração');
      });
    },
    saveNewBatch() {
      const data = {
        benefitType: this.batch.benefitType,
        generateBy: this.batch.generateBy,
        insuranceCarrierId: JSON.parse(JSON.stringify(this.batchInsuranceCarrierId)),
        layout: this.batch.layout,
        minLife: this.batch.minLife,
        movementTypes: this.batch.movementType,
        batchType: this.batch.batchType,
        status: this.batch.status === 1,
        userId: this.batch.userId,
        cronExpression: this.batch.cronExpression,
        allowScheduling: this.batch.allowScheduling,
        restrictContracts: this.restrictContracts.map((contract) => ({ contractId: contract.id })),
      };
      this.insuranceCarrierBatchConfigService.Save(data).then(() => {
        this.customizeSnackbarMessage('success', 'Configuração criada com sucesso');
        this.$router.push({ name: 'BatchConfiguration' });
      }).catch((error) => {
        this.cronExpressionError = error.response.data.fields.find((f) => f.field === 'cronExpression');
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao criar configuração');
      });
    },
    loadMovementType() {
      this.isLoadingMovementTypeList = true;
      this.movementRecordTypeService.FindAll().then((response) => {
        this.movementTypeList = response.data;
        this.isLoadingMovementTypeList = false;
      }).catch(() => {
        this.isLoadingMovementTypeList = false;
      });
    },
    loadInsuranceCarrierLayoutList(insuranceCarrierId) {
      this.isLoadingLayoutList = true;
      this.layoutService.FindAllByFilter({ insuranceCarrierId, entityType: 'INSURANCE_CARRIER' }).then((response) => {
        this.layoutList = response.data.content;
        this.isLoadingLayoutList = false;
      }).catch(() => {
        this.isLoadingLayoutList = false;
      });
    },
    loadBenetiType() {
      this.isLoadingBenefitTypeList = true;
      this.benefitService.FindAll().then((response) => {
        if (response && response.data && response.data.records.length) {
          this.benefitTypes = response.data.records.filter((benefit) => benefit.xipp_contract_record_type_code !== null);
        }
        this.isLoadingBenefitTypeList = false;
      }).catch(() => {
        this.isLoadingBenefitTypeList = false;
      });
    },
    watchFocus(item) {
      if (item) {
        return true;
      }
      return false;
    },
    handleBatchTypeChange() {
      if (this.batch.batchType === 'API') {
        this.batch.layoutId = '';
        this.batch.allowScheduling = '';
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },

  computed: {
    ...mapGetters({
      batchInsuranceCarrierId: 'batch/batchInsuranceCarrierId'
    })
  },

  created() {
    this.rule = new Rules();
    this.layoutService = new LayoutService();
    this.movementRecordTypeService = new MovementRecordTypeService();
    this.benefitService = new BenefitService();
    this.insuranceCarrierBatchConfigService = new InsuranceCarrierBatchConfigService();
    this.contractService = new ContractService();
    this.financialGroupService = new FinancialGroupService();
  },
};
</script>
