/* eslint-disable */
import HttpService from '../HttpService'

export default class InsuranceCarrierBatchConfigService {
  constructor() {
    this._httpService = new HttpService('/sdi/insurance-carrier-batch-config');
  }

  async Save(data) {
    return await this._httpService.post('', data);
  }

  async FindAll() {
    return await this._httpService.get('');
  }

  async FindById(id) {
    return await this._httpService.get(`/${id}`);
  }
  
  async FindAllByFilter(params) {
    return await this._httpService.get('', params);
  }

  async UploadBatch(id, data) {
    return await this._httpService.put(`/${id}`, data);
  }

  async DeleteBatch(id) {
    return await this._httpService.delete(`/${id}`);
  }

  async ExecuteBatch(id) {
    return await this._httpService.post(`/${id}/execute-manual`);
  }
}