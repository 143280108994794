/* eslint-disable */
import HttpService from '../HttpService'

export default class LayoutService {
  constructor() {
    this._httpService = new HttpService('/sdi/layout');
  }
  async FindAllByFilter(params) {
    return await this._httpService.get('', params);
  }

  async LoadReferenceFile(data, formData) {
    return await this._httpService.post(`/parse?${data}`, formData);
  }

  async Save(data) {
    return await this._httpService.post('', data);
  }

  async Update(id, data) {
    return await this._httpService.put(`/${id}`, data);
  }

  async UpdateLayoutStatus(id, status) {
    return await this._httpService.put(`/${id}/${status}`);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
}
